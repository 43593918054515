import { defineStore } from 'pinia'
import { UserState } from '@/types/stores/UserState'

const useUserStore = defineStore(
    'user',
    {
        state: (): UserState => ({
            authenticated: false,
            token: null,
            user: {
                cr_id: null,
                givenName: '',
                surname: '',
                email: null,
                user_roles: [],
                actForMemberId: null,
                actForMemberName: ''
            }
        }),
        actions: {
            async onValidTokenExists (data: KeycloakInstance) {
                this.authenticated = true
                this.token = typeof data.token === 'string' ? data.token : null
                this.user.email = getStringOrNull(data.idTokenParsed?.email)
                this.user.givenName = getString(data.idTokenParsed?.given_name)
                this.user.surname = getString(data.idTokenParsed?.family_name)
                this.user.user_roles = getArrayOfString(data.idTokenParsed?.cr_userrole)
                this.user.cr_id = getStringOrNull(data.idTokenParsed?.cr_id)
            },
            setActForMember (data: any) {
                this.user.actForMemberId = getStringOrNull(data?.actForMemberId)
                this.user.actForMemberName = getString(data?.actForMemberName)
            }
        }
    }
)

function getString (value: any): string {
    if (typeof value === 'string') {
        return value
    }
    return ''
}

function getStringOrNull (value: any): string | null {
    if (typeof value === 'string') {
        return value
    }
    return null
}

function getArrayOfString (value: any): string[] {
    if (value instanceof Array) {
        let isValid = true
        for (let i = 0; i < value.length; i++) {
            if (typeof value[i] !== 'string') {
                isValid = false
                break
            }
        }
        return isValid ? value : []
    }
    return []
}

export default useUserStore
