import {
    createRouter,
    createWebHistory,
    NavigationGuardNext,
    RouteLocationNormalized,
    RouteRecordRaw
} from 'vue-router'
import { dispatchEvent } from '@/helper/polyfill'
import useAppStore from '@/stores/app'
import useUserStore from '@/stores/user'

import IndexView from '@/views/index.vue'
import EpaperView from '@/views/e-paper.vue'

const BASE_PATH_MICROFRONTEND_CONTAINER = import.meta.env.VITE_APP_BASE_PATH
const PUBLIC_ENTRY_ROUTE_MICROFRONTEND_APP = import.meta.env.VITE_APP_PUBLIC_ENTRY_ROUTE

const setIsLoading = (isLoading: boolean) => {
    const appStore = useAppStore()
    appStore.isLoading = isLoading
}

const isAllowed = (to: RouteLocationNormalized, from: RouteLocationNormalized, next: NavigationGuardNext) => {
    // Always allowed
    setIsLoading(false)
    next()
}

const isAuthenticatedAndAllowed = (to: RouteLocationNormalized, from: RouteLocationNormalized, next: NavigationGuardNext) => {
    const userStore = useUserStore()

    const isAuthenticated = async (toRoute: RouteLocationNormalized, fromRoute: RouteLocationNormalized, nextRoute: NavigationGuardNext, timeout: number = 100, timeElapsed: number = 0) => {
        if (userStore.authenticated) {
            isAllowed(toRoute, fromRoute, nextRoute)
        } else if (timeElapsed >= 4000 && (window.keycloak && window.keycloak.token)) {
            await userStore.onValidTokenExists(window.keycloak)
            isAllowed(toRoute, fromRoute, nextRoute)
        } else if (timeElapsed >= 4000) {
            dispatchEvent('redirectToLogin')
        } else {
            timeout = Math.max(Math.min(timeout, 4000 - timeElapsed), 1)
            setTimeout(() => {
                isAuthenticated(toRoute, fromRoute, nextRoute, timeout * 2, timeElapsed + timeout)
            }, timeout)
        }
    }

    isAuthenticated(to, from, next)
}

const routes: RouteRecordRaw[] = [
    {
        path: `/${BASE_PATH_MICROFRONTEND_CONTAINER}/${PUBLIC_ENTRY_ROUTE_MICROFRONTEND_APP}/`,
        name: 'index',
        component: IndexView,
        beforeEnter: isAuthenticatedAndAllowed
    },
    {
        path: `/${BASE_PATH_MICROFRONTEND_CONTAINER}/${PUBLIC_ENTRY_ROUTE_MICROFRONTEND_APP}/e-paper`,
        name: 'epaper',
        component: EpaperView,
        props: (route: RouteLocationNormalized) => ({
            file: route.query.file,
            name: route.query.name
        }),
        beforeEnter: isAuthenticatedAndAllowed
    }
]

const getRouter = () => {
    const router = createRouter({
        history: createWebHistory('/'),
        routes,
        scrollBehavior: to => {
            if (!to.hash) {
                window.scroll(0, 0)
            }
        }
    })

    router.beforeEach(async () => {
        setIsLoading(true)
    })

    router.afterEach((to, from, failure) => {
        setIsLoading(false)
        if (!failure && to.name !== from.name) {
            window.wtSmart.trackPage()
        }
    })

    return router
}

export default getRouter
